const $notice = $('.js-notice');
const $closeBtn = $('.js-notice-close');

export default function() {
  $closeBtn
    .on('click', function(e) {
      e.preventDefault();

      $notice
        .animate({
          height: 0,
          opacity: 0,
        }, 500)
    });
}
