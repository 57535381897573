//require('./bx-slider.js');
var Swiper = require('swiper');


export default function() {
	
	//swiper settings desktop
	var swiperSettingsDesktop = {
		speed: 400,
		slidesPerView:4,
		slidesPerGroup:4,
		slidesPerColumn:2,
		//slidesPerColumnFill:'row',
		//slidesPerColumnFill:2,
		spaceBetween: 30,
		grabCursor:true,
		autoplay:{
			delay:5000,
		},
		navigation: {
		    nextEl: '.swiper-button-next',
		    prevEl: '.swiper-button-prev',
		},
		pagination: {
			el: '.swiper-pagination',
			clickable: true,
		},
		keyboard:{
			enabled:true,
		},
		lazy: {
		    loadPrevNext: true,
		    loadPrevNextAmount:300
		}
	};
	//swiper settings desktop small
	var swiperSettingsDesktopSmall = {
		speed: 400,
		slidesPerView:3,
		slidesPerGroup:3,
		slidesPerColumn:2,
		//slidesPerColumnFill:'row',
		spaceBetween: 15,
		grabCursor:true,
		autoplay:{
			delay:5000,
		},
		navigation: {
		    nextEl: '.swiper-button-next',
		    prevEl: '.swiper-button-prev',
		},
		pagination: {
			el: '.swiper-pagination',
			clickable: true,
		},
		keyboard:{
			enabled:true,
		},
		lazy: {
		    loadPrevNext: true,
		    loadPrevNextAmount:300
		}
	};
	//swiper settings mobile
	var swiperSettingsMobile = {
		speed: 400,
		slidesPerView:2,
		slidesPerGroup:2,
		slidesPerColumn:2,
		//slidesPerColumnFill:'column',
		spaceBetween: 15,
		navigation: {
		    nextEl: '.swiper-button-next',
		    prevEl: '.swiper-button-prev',
		},
		pagination: {
			el: '.swiper-pagination',
			clickable: true,
		},
		keyboard:{
			enabled:true,
		},
		lazy: {
		    loadPrevNext: true,
		    loadPrevNextAmount:300
		}
	};
	
	//depending on window size, load the correct swiper settings
	var windowSize = getWindowSize();
	var mySwiper = null;
	if(windowSize.height > 960 && windowSize.width > 960){
		mySwiper = new Swiper('.swiper-container', swiperSettingsDesktop);
	}else if(windowSize.height > 800 && windowSize.width > 767){
		mySwiper = new Swiper('.swiper-container', swiperSettingsDesktopSmall);
	}else{
		mySwiper = new Swiper('.swiper-container', swiperSettingsMobile);
	}
	//dbounced function for window resize
	var myEfficientFn = debounce(function() {
		mySwiper.destroy(false, true);
		var ws = getWindowSize();
		if(ws.height > 960 && ws.width > 960){
			mySwiper = new Swiper('.swiper-container', swiperSettingsDesktop);
		}else if(ws.height > 800 && ws.width > 767){
			mySwiper = new Swiper('.swiper-container', swiperSettingsDesktopSmall);
		}else{
			mySwiper = new Swiper('.swiper-container', swiperSettingsMobile);
		}
	}, 250);
	window.addEventListener('resize', myEfficientFn);

	//tooltips
	tippy('.swiper-container a.thumbnail-link',{
		arrow:true,
		size:'large',
		theme:'swiper',
		delay:100,
		content: el => el.parentElement.querySelector('.caption-tooltip'),
		distance:-180
	});

	//lightbox w captions
	jQuery(document).ready(function($){
		baguetteBox.run('.swiper-container',{
			captions:function(el){
				var caption = $(el).parent().find('.caption-lightbox');
				return (caption.length ? caption.html() : false );
			}
		});
		//lightbox caption show/hide
		$('body').on('click','#baguetteBox-overlay .hide',function(e){
			$(this).closest('#baguetteBox-overlay').toggleClass('info-hidden');
		});
	});
	
	
	
	
  function getWindowSize(){
	  	// vanilla JS window width and height
		var w=window,
		d=document,
		e=d.documentElement,
		g=d.getElementsByTagName('body')[0],
		x=w.innerWidth||e.clientWidth||g.clientWidth,
		y=w.innerHeight||e.clientHeight||g.clientHeight;
		return {width:x,height:y};
  }
  
    // Returns a function, that, as long as it continues to be invoked, will not
	// be triggered. The function will be called after it stops being called for
	// N milliseconds. If `immediate` is passed, trigger the function on the
	// leading edge, instead of the trailing.
	function debounce(func, wait, immediate) {
		var timeout;
		return function() {
			var context = this, args = arguments;
			var later = function() {
				timeout = null;
				if (!immediate) func.apply(context, args);
			};
			var callNow = immediate && !timeout;
			clearTimeout(timeout);
			timeout = setTimeout(later, wait);
			if (callNow) func.apply(context, args);
		};
	};
}
