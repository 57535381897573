/*jshint -W109 */
/* globals wp_settings, GMaps, google*/

//import $ from 'jquery';

jQuery(document).ready(function($){
	
	$('body.home a.navbar-brand, body.home .homelink a, body.home a.homelink').click(function(e){
		e.preventDefault();
		$('a.back-to-top').click();
	});
	$('body').scrollspy({ target: '.navbar-nav' });
	
	
	$('.more-section').on('hidden.bs.collapse shown.bs.collapse', function () {
		var toggle = $(this).parent().find('a.more-toggle').eq(0);
		var parent_container = $(this).parent();
		var toggletext_current = toggle.html();
		var toggletext_next = toggle.attr('data-toggletext');
		toggle.text(toggletext_next);
		toggle.attr('data-toggletext',toggletext_current); 
		parent_container.toggleClass('collapse-shown');
	});
	
	//google map 
	$('.gmap').each(function(){
		var $container = $(this);
		$container.hide();
		var id = $container.attr('id');	
		var $lat = $container.attr('data-latitude');
		var $lon = $container.attr('data-longitude');
		var $zoom = $container.attr('data-zoom');
		
		if($lat && $lon){
			var myLatLng = {lat:parseFloat($lat), lng:parseFloat($lon)};	//40.6736422,-73.991429
			var map = new google.maps.Map(document.getElementById(id),{
				zoom:parseInt($zoom),//13,
				center:myLatLng,
				scrollwheel:false
			});
		
			var marker = new google.maps.Marker({
			    position: myLatLng,
			    map: map
			});
			$container.show();
		}
	});
	
	$('.navbar-nav a').click(function(){
		if($(this).closest('.navbar-collapse').hasClass('show')){
			$(this).closest('.navbar').find('.navbar-toggler').eq(0).click();
		}
	});

});
