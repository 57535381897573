import ScrollMagic from 'scrollmagic';

const $menuItems = $('.js-nav-menu a, a.js-scrollto, ul.menu a');
const $sections = $('.js-scroll-section');
const $nav = $('nav.navbar.fixed-top');
const scrollMagicController = new ScrollMagic.Controller();

export default function() {

  initMenuHashes();
  activateMenuSticky();
  activateSectionScrollSpy();

  $menuItems
    .on('click', function(e) {
      //if the anchor href actually points to a selector on the page, then scroll to it, otherwise treat it like a normal link
      if( $($(this).attr('href')).length ){
	      e.preventDefault();
	      let offset = $(this.hash).offset().top;
		  //console.log($(this.hash));
	      $('html, body')
	        .animate({
	          scrollTop: offset - $nav.height(),
	        }, 300);
      }
      
    });
}

function initMenuHashes() {
  if($('#about').length){
	  $('.menu-about a')
	  .attr('href','#about')
	  .removeClass('active');
  }
  if($('#membership').length){
	  $('.menu-membership a')
	  .attr('href','#membership');
	  $('.menu-donate-now a')
	  .attr('href','#membership');
  }
  
}

function activateMenuSticky() {
  // Position fixed the menu
  new ScrollMagic.Scene({
    triggerElement: '.site-wrap',
    triggerHook: 0,
  })
    .setClassToggle('nav.navbar', 'is-sticky')
    .addTo(scrollMagicController);

  if($('.js-menu-overlay-point').length){
	  
	  //for top nav
	  new ScrollMagic.Scene({
	    triggerElement: '.js-menu-overlay-point',
	    triggerHook: 0.5,
	  })
	  .setClassToggle('nav.navbar', 'is-stuck')
	  .addTo(scrollMagicController);
	  
	  //for scroll to top link
	  new ScrollMagic.Scene({
	    triggerElement: '.js-menu-overlay-point',
	    triggerHook: 0.5,
	  })
	  .setClassToggle('.back-to-top','visible')
	  .addTo(scrollMagicController);
	  
  }
}

function activateSectionScrollSpy() {

  $sections
    .each(function() {
      let id = $(this).attr('id');
      let height = $(this).height();
      let section = '#' + id;
      $('a[href="#'+id+'"]').each(function(){
	      $(this).parent('li').addClass('menu-'+id);
	  });
	  let item = '.menu-' + id;
	  //console.log(height);
	  //console.log(item);
	  if(id && height > 0 && $(item).length > 0){
		  new ScrollMagic.Scene({
	        triggerElement: section,
	        duration: height,
	        triggerHook: 0,
	        offset:-1 * $nav.height() - 1
	      }).setClassToggle(item, 'active').addTo(scrollMagicController);
	  }
    });
}
